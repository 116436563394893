import update from 'immutability-helper';
import { REHYDRATE } from 'redux-persist/constants';
import isNil from 'ramda/src/isNil';

import ActionTypes from 'constants/ActionTypes';
import ListPresenceTypes from 'constants/ListPresenceTypes';
import { OR } from 'constants/LogicOperators';

const initialState = {
    active: false,
    settings: {
        cpcFrom: 0.0,
        cpcTo: null,
        estimatedTrafficFrom: 0,
        includedContentTypes: [],
        includedSearchIntentTypes: [],
        estimatedTrafficTo: null,
        excludedKW: [],
        excludedOperator: OR,
        includedKW: [],
        includedOperator: OR,
        listPresenceType: ListPresenceTypes.ALL,
        ppcFrom: 0,
        ppcTo: null,
        rankFrom: 0,
        rankTo: null,
        searchCountFrom: 0,
        searchCountTo: null,
        serpPositionFrom: 0,
        serpPositionTo: null,
        wordCountFrom: 0,
        wordCountTo: null,
    },
    quickSettings: {
        search: '',
    },
    visibility: false,
};

const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_ALL_CLOSE: {
            return update(state, {
                visibility: { $set: false },
            });
        }
        case ActionTypes.UI_FILTER_VISIBILITY_TOGGLE: {
            return update(state, {
                visibility: {
                    $apply: visibility => !visibility,
                },
            });
        }
        case ActionTypes.UI_FILTER_ACTIVE_SET: {
            return update(state, {
                active: {
                    $set: action.payload,
                },
            });
        }
        case ActionTypes.UI_FILTER_SETTINGS_SET: {
            return update(state, {
                active: {
                    $set: true,
                },
                settings: {
                    $set: action.payload,
                },
                visibility: {
                    $set: false,
                },
            });
        }
        case ActionTypes.UI_FILTER_SHOW: {
            return update(state, {
                visibility: {
                    $set: true,
                },
            });
        }
        case ActionTypes.UI_FILTER_RESET: {
            return update(state, {
                active: {
                    $set: false,
                },
                settings: {
                    $set: initialState.settings,
                },
                visibility: {
                    $set: false,
                },
            });
        }
        case ActionTypes.UI_FILTER_QUICK_SETTINGS_SEARCH: {
            return update(state, {
                quickSettings: {
                    search: { $set: action.payload },
                },
            });
        }
        case REHYDRATE: {
            const savedState = action.payload.ui;

            if (!isNil(savedState) && !isNil(savedState.filter)) {
                return update(state, {
                    settings: {
                        cpcFrom: {
                            $set: savedState.filter.settings.cpcFrom || initialState.settings.cpcFrom,
                        },
                        cpcTo: {
                            $set: savedState.filter.settings.cpcTo || initialState.settings.cpcTo,
                        },
                        estimatedTrafficFrom: {
                            $set:
                                savedState.filter.settings.estimatedTrafficFrom ||
                                initialState.settings.estimatedTrafficFrom,
                        },
                        estimatedTrafficTo: {
                            $set:
                                savedState.filter.settings.estimatedTrafficTo ||
                                initialState.settings.estimatedTrafficTo,
                        },
                        excludedKW: {
                            $set: savedState.filter.settings.excludedKW || initialState.settings.excludedKW,
                        },
                        excludedOperator: {
                            $set: savedState.filter.settings.excludedOperator || initialState.settings.excludedOperator,
                        },
                        includedKW: {
                            $set: savedState.filter.settings.includedKW || initialState.settings.includedKW,
                        },
                        includedOperator: {
                            $set: savedState.filter.settings.includedOperator || initialState.settings.includedOperator,
                        },
                        listPresenceType: {
                            $set: savedState.filter.settings.listPresenceType || initialState.settings.listPresenceType,
                        },
                        ppcFrom: {
                            $set: savedState.filter.settings.ppcFrom || initialState.settings.ppcFrom,
                        },
                        ppcTo: {
                            $set: savedState.filter.settings.ppcTo || initialState.settings.ppcTo,
                        },
                        rankFrom: {
                            $set: savedState.filter.settings.rankFrom || initialState.settings.rankFrom,
                        },
                        rankTo: {
                            $set: savedState.filter.settings.rankTo || initialState.settings.rankTo,
                        },
                        searchCountFrom: {
                            $set: savedState.filter.settings.searchCountFrom || initialState.settings.searchCountFrom,
                        },
                        searchCountTo: {
                            $set: savedState.filter.settings.searchCountTo || initialState.settings.searchCountTo,
                        },
                        serpPositionFrom: {
                            $set: savedState.filter.settings.serpPositionFrom || initialState.settings.serpPositionFrom,
                        },
                        serpPositionTo: {
                            $set: savedState.filter.settings.serpPositionTo || initialState.settings.serpPositionTo,
                        },
                        wordCountFrom: {
                            $set: savedState.filter.settings.wordCountFrom || initialState.settings.wordCountFrom,
                        },
                        wordCountTo: {
                            $set: savedState.filter.settings.wordCountTo || initialState.settings.wordCountTo,
                        },
                        includedContentTypes: {
                            $set: savedState.filter.settings.includedContentTypes || initialState.settings.includedContentTypes,
                        }
                    },
                });
            } else {
                return state;
            }
        }
        default: {
            return state;
        }
    }
};

export default filterReducer;
