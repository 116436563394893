import React from 'react';
import { bool, func, number } from 'prop-types';
import MessageHolder from 'components/messages/MessageHolder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* eslint-disable max-len */
function RefreshConfirmMessage(props) {
    if (props.visible) {
        return (
            <MessageHolder onClose={props.onClose}>
                <div className="mg-modal-header is-info">
                    <FontAwesomeIcon icon="sync-alt" aria-label="refresh" />
                </div>
                <div className="mg-modal-content">
                    <h3 className="font-30 uk-text-bold color-black">Refresh Keyword difficulty and Content types</h3>
                    <p className="uk-margin-top font-18">
                        This will refresh keyword difficulties and content types of{' '}
                        <strong>
                            {props.selectedKeywordCount} keyword{props.selectedKeywordCount > 1 ? 's' : ''}
                        </strong>
                        . Currently you have <strong>{props.serpRequestRemaining} SERP lookups</strong> available to do
                        this.
                    </p>
                    <p className="uk-margin-top font-18">Don’t close the browser tab until the processing is over.</p>
                    <p className="uk-margin-top font-18">Do you want to proceed?</p>
                    <div>
                        <button
                            className="uk-width-1-1 mg-btn is-blue is-small mg-margin-t-10"
                            onClick={props.proceed}
                            type="button"
                        >
                            Proceed
                        </button>
                        <button
                            className="uk-width-1-1 mg-btn is-white is-small mg-margin-t-10"
                            onClick={props.onClose}
                            type="button"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </MessageHolder>
        );
    } else {
        return null;
    }
}
/* eslint-enable max-len */

RefreshConfirmMessage.propTypes = {
    proceed: func.isRequired,
    onClose: func.isRequired,
    visible: bool.isRequired,
    selectedKeywordCount: number.isRequired,
    serpRequestRemaining: number.isRequired,
};

export default RefreshConfirmMessage;
