import update from 'immutability-helper';
import ActionTypes from 'constants/ActionTypes';

const initialState = {
    fetching: false,
    error: {
        status: null,
        type: null,
    },
    data: {},
};

const searchIntentReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.DATA_SEARCH_INTENT_FETCHING: {
            return update(state, {
                fetching: { $set: true },
            });
        }
        case ActionTypes.DATA_SEARCH_INTENT_RECEIVED: {
            return update(state, {
                fetching: { $set: false },
                data: { $set: action.payload },
                error: { $set: initialState.error },
            });
        }
        case ActionTypes.DATA_SEARCH_INTENT_ERROR: {
            return update(state, {
                data: { $set: initialState.data },
                fetching: { $set: false },
                error: {
                    status: { $set: action.payload.status },
                    type: { $set: action.payload.type },
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default searchIntentReducer;