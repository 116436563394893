export const KEYWORD_COLUMNS = {
    CONTENT_TYPE: 'CONTENT_TYPE',
    SEARCH_INTENT: 'SEARCH_INTENT',
    AVG_SEARCH_VOLUME: 'AVG_SEARCH_VOLUME',
    TREND: 'TREND',
    CPC: 'CPC',
    PPC: 'PPC',
    EV: 'EV',
    POS: 'POS',
    KEYWORD_DIFFICULTY: 'KEYWORD_DIFFICULTY',
};

export const SEARCH_BY_KEYWORD_COLUMNS = [
    KEYWORD_COLUMNS.CONTENT_TYPE,
    KEYWORD_COLUMNS.SEARCH_INTENT,
    KEYWORD_COLUMNS.AVG_SEARCH_VOLUME,
    KEYWORD_COLUMNS.TREND,
    KEYWORD_COLUMNS.CPC,
    KEYWORD_COLUMNS.PPC,
    KEYWORD_COLUMNS.KEYWORD_DIFFICULTY,
];

export const SEARCH_BY_DOMAIN_COLUMNS = [
    KEYWORD_COLUMNS.CONTENT_TYPE,
    KEYWORD_COLUMNS.SEARCH_INTENT,
    KEYWORD_COLUMNS.POS,
    KEYWORD_COLUMNS.AVG_SEARCH_VOLUME,
    KEYWORD_COLUMNS.EV,
    KEYWORD_COLUMNS.CPC,
    KEYWORD_COLUMNS.PPC,
    KEYWORD_COLUMNS.KEYWORD_DIFFICULTY,
];

export const KEYWORD_COLUMNS_MAP = {
    [KEYWORD_COLUMNS.CONTENT_TYPE]: 'Content type (Content)',
    [KEYWORD_COLUMNS.SEARCH_INTENT]: 'Search intent (Intent)',
    [KEYWORD_COLUMNS.AVG_SEARCH_VOLUME]: 'Avg. search volume (Search)',
    [KEYWORD_COLUMNS.EV]: 'Estimated visits',
    [KEYWORD_COLUMNS.POS]: 'Position',
    [KEYWORD_COLUMNS.TREND]: 'Search Volume Trend (Trend)',
    [KEYWORD_COLUMNS.CPC]: 'Cost Per Click (CPC)',
    [KEYWORD_COLUMNS.PPC]: 'PPC Competition (PPC)',
    [KEYWORD_COLUMNS.KEYWORD_DIFFICULTY]: 'Keyword difficulty (KD)',
};
