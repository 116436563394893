export const SERP_COLUMNS = {
    DOMAIN_AUTHORITY: 'DOMAIN_AUTHORITY',
    PAGE_AUTHORITY: 'PAGE_AUTHORITY',
    CITATION_FLOW: 'CITATION_FLOW',
    TRUST_FLOW: 'TRUST_FLOW',
    EXTERNAL_BACKLINKS: 'EXTERNAL_BACKLINKS',
    FACEBOOK_SHARES: 'FACEBOOK_SHARES',
    LINK_PROFILE_STRENGTH: 'LINK_PROFILE_STRENGTH',
    ESTIMATED_VISITS: 'ESTIMATED_VISITS',
};

export const SERP_COLUMNS_MAP = {
    [SERP_COLUMNS.DOMAIN_AUTHORITY]: 'Domain Authority (DA)',
    [SERP_COLUMNS.PAGE_AUTHORITY]: 'Page Authority (PA)',
    [SERP_COLUMNS.CITATION_FLOW]: 'Citation Flow (CF)',
    [SERP_COLUMNS.TRUST_FLOW]: 'Trust Flow (TF)',
    [SERP_COLUMNS.EXTERNAL_BACKLINKS]: 'External backlinks (Links)',
    [SERP_COLUMNS.FACEBOOK_SHARES]: 'Facebook shares (FB)',
    [SERP_COLUMNS.LINK_PROFILE_STRENGTH]: 'Link Profile Strength (LPS)',
    [SERP_COLUMNS.ESTIMATED_VISITS]: 'Estimated Visits (EV)',
};
