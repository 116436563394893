import ActionTypes from 'constants/ActionTypes';

/**
 * Redux action creators for data (results, serpResults, locations)
 * fetching and posting.
 */

export const requestedResultsAction = () => ({
    type: ActionTypes.DATA_RESULTS_REQUESTED,
});

export const fetchingResultsAction = () => ({
    type: ActionTypes.DATA_RESULTS_FETCHING,
});

export const receivedResultsAction = payload => ({
    type: ActionTypes.DATA_RESULTS_RECEIVED,
    error: false,
    payload,
});

export const errorResultsAction = payload => ({
    type: ActionTypes.DATA_RESULTS_ERROR,
    error: true,
    payload,
});

export const requestedKeywordsByDomainResultsAction = subSource => ({
    type: ActionTypes.DATA_KEYWORDS_BY_DOMAIN_RESULTS_REQUESTED,
    error: false,
    payload: subSource,
});

export const fetchingKeywordsByDomainResultsAction = () => ({
    type: ActionTypes.DATA_KEYWORDS_BY_DOMAIN_RESULTS_FETCHING,
});

export const receivedKeywordsByDomainResultsAction = payload => ({
    type: ActionTypes.DATA_KEYWORDS_BY_DOMAIN_RESULTS_RECEIVED,
    error: false,
    payload,
});

export const errorKeywordsByDomainResultsAction = payload => ({
    type: ActionTypes.DATA_KEYWORDS_BY_DOMAIN_RESULTS_ERROR,
    error: true,
    payload,
});

export const skippedKeywordsByDomainResultsAction = () => ({
    type: ActionTypes.DATA_KEYWORDS_BY_DOMAIN_RESULTS_SKIPPED,
});

export const emptyKeywordsByDomainResultsAction = () => ({
    type: ActionTypes.DATA_KEYWORDS_BY_DOMAIN_RESULTS_EMPTY,
});

export const requestedCompetitorsByDomainResultsAction = () => ({
    type: ActionTypes.DATA_COMPETITOR_BY_DOMAINS_RESULTS_REQUESTED,
});

export const fetchingCompetitorsByDomainResultsAction = () => ({
    type: ActionTypes.DATA_COMPETITOR_BY_DOMAINS_RESULTS_FETCHING,
});

export const receivedCompetitorsByDomainResultsAction = payload => ({
    type: ActionTypes.DATA_COMPETITOR_BY_DOMAINS_RESULTS_RECEIVED,
    payload,
});

export const errorCompetitorsByDomainResultsAction = payload => ({
    type: ActionTypes.DATA_COMPETITOR_BY_DOMAINS_RESULTS_ERROR,
    error: true,
    payload,
});

export const skippedCompetitorsByDomainResultsAction = () => ({
    type: ActionTypes.DATA_COMPETITOR_BY_DOMAINS_RESULTS_SKIPPED,
});

export const emptyCompetitorsByDomainResultsAction = () => ({
    type: ActionTypes.DATA_COMPETITOR_BY_DOMAINS_RESULTS_EMPTY,
});

export const receivedKeywordRankAction = (id, rank, rankUpdatedAt, contentTypes) => ({
    type: ActionTypes.DATA_RESULTS_KEYWORD_RANK_RECEIVED,
    error: false,
    payload: { id, rank, rankUpdatedAt, contentTypes },
});

export const emptyKeywordRankAction = id => ({
    type: ActionTypes.DATA_RESULTS_KEYWORD_RANK_EMPTY,
    error: false,
    payload: { id },
});

export const requestedSerpResultsAction = () => ({
    type: ActionTypes.DATA_SERP_RESULTS_REQUESTED,
});

export const requestedUncachedSerpResultsAction = () => ({
    type: ActionTypes.DATA_SERP_RESULTS_UNCACHED_REQUESTED,
});

export const fetchingSerpResultsAction = () => ({
    type: ActionTypes.DATA_SERP_RESULTS_FETCHING,
});

export const receivedSerpResultsAction = payload => ({
    type: ActionTypes.DATA_SERP_RESULTS_RECEIVED,
    error: false,
    payload,
});

export const errorSerpResultsAction = payload => ({
    type: ActionTypes.DATA_SERP_RESULTS_ERROR,
    error: true,
    payload,
});

export const requestedSerpResultsMoreAction = () => ({
    type: ActionTypes.DATA_SERP_RESULTS_MORE_REQUESTED,
});

export const fetchingSerpResultsMoreAction = () => ({
    type: ActionTypes.DATA_SERP_RESULTS_MORE_FETCHING,
});

export const receivedSerpResultsMoreAction = payload => ({
    type: ActionTypes.DATA_SERP_RESULTS_MORE_RECEIVED,
    error: false,
    payload,
});

export const receivedNoMoreSerpResultsAction = () => ({
    type: ActionTypes.DATA_SERP_RESULTS_MORE_NO_DATA_RECEIVED,
});

export const receivedNoSerpResultsAction = () => ({
    type: ActionTypes.DATA_SERP_RESULTS_NO_DATA_RECEIVED,
});

export const errorSerpResultsMoreAction = payload => ({
    type: ActionTypes.DATA_SERP_RESULTS_MORE_ERROR,
    error: true,
    payload,
});

export const requestedLocationsAction = query => ({
    type: ActionTypes.DATA_LOCATIONS_REQUESTED,
    error: false,
    payload: query,
});

export const fetchingLocationsAction = () => ({
    type: ActionTypes.DATA_LOCATIONS_FETCHING,
});

export const receivedLocationsAction = payload => ({
    type: ActionTypes.DATA_LOCATIONS_RECEIVED,
    error: false,
    payload,
});

export const errorLocationsAction = payload => ({
    type: ActionTypes.DATA_LOCATIONS_ERROR,
    error: true,
    payload,
});

export const fetchingTrendsAction = () => ({
    type: ActionTypes.DATA_TRENDS_FETCHING,
});

export const receivedTrendsAction = ({ data, meta }) => ({
    type: ActionTypes.DATA_TRENDS_RECEIVED,
    error: false,
    payload: {
        data,
        meta,
    },
});

export const errorTrendsAction = payload => ({
    type: ActionTypes.DATA_TRENDS_ERROR,
    error: true,
    payload,
});

export const requestedHistoryAction = () => ({
    type: ActionTypes.DATA_HISTORY_REQUESTED,
});

export const fetchingHistoryAction = () => ({
    type: ActionTypes.DATA_HISTORY_FETCHING,
});

export const receivedHistoryAction = payload => ({
    type: ActionTypes.DATA_HISTORY_RECEIVED,
    error: false,
    payload,
});

export const errorHistoryAction = payload => ({
    type: ActionTypes.DATA_HISTORY_ERROR,
    error: true,
    payload,
});

export const skippedHistoryAction = () => ({
    type: ActionTypes.DATA_HISTORY_SKIPPED,
});

export const requestedDeleteHistoryAction = () => ({
    type: ActionTypes.DATA_HISTORY_DELETE_REQUESTED,
});

export const errorDeleteHistoryAction = payload => ({
    type: ActionTypes.DATA_HISTORY_DELETE_ERROR,
    error: true,
    payload,
});

export const receivedDeleteHistoryAction = () => ({
    type: ActionTypes.DATA_HISTORY_DELETE_RECEIVED,
});

export const receivedDeleteKdHistoryAction = () => ({
    type: ActionTypes.DATA_KD_HISTORY_DELETE_RECEIVED,
});

export const requestedKdHistoryAction = () => ({
    type: ActionTypes.DATA_KD_HISTORY_REQUESTED,
});

export const fetchingKdHistoryAction = () => ({
    type: ActionTypes.DATA_KD_HISTORY_FETCHING,
});

export const receivedKdHistoryAction = payload => ({
    type: ActionTypes.DATA_KD_HISTORY_RECEIVED,
    error: false,
    payload,
});

export const errorKdHistoryAction = payload => ({
    type: ActionTypes.DATA_KD_HISTORY_ERROR,
    error: true,
    payload,
});

export const skippedKdHistoryAction = () => ({
    type: ActionTypes.DATA_KD_HISTORY_SKIPPED,
});

export const requestedDeleteKdHistoryAction = () => ({
    type: ActionTypes.DATA_KD_HISTORY_DELETE_REQUESTED,
});

export const errorDeleteKdHistoryAction = payload => ({
    type: ActionTypes.DATA_KD_HISTORY_DELETE_ERROR,
    error: true,
    payload,
});

export const requestedUrlDataAction = () => ({
    type: ActionTypes.DATA_URL_DATA_REQUESTED,
});

export const fetchingUrlDataAction = () => ({
    type: ActionTypes.DATA_URL_DATA_FETCHING,
});

export const receivedUrlDataAction = payload => ({
    type: ActionTypes.DATA_URL_DATA_RECEIVED,
    error: false,
    payload,
});

export const errorUrlDataAction = payload => ({
    type: ActionTypes.DATA_URL_DATA_ERROR,
    error: true,
    payload,
});

export const setComparingBoxUrlProtocol = protocol => ({
    type: ActionTypes.UI_COMPARING_BOX_URL_PROTOCOL_SET,
    error: false,
    payload: protocol,
});

export const setComparingBoxUrl = url => ({
    type: ActionTypes.UI_COMPARING_BOX_URL_SET,
    error: false,
    payload: url,
});

export const requestedListsAction = () => ({
    type: ActionTypes.DATA_LISTS_REQUESTED,
});

export const fetchingListsAction = () => ({
    type: ActionTypes.DATA_LISTS_FETCHING,
});

export const receivedListsAction = payload => ({
    type: ActionTypes.DATA_LISTS_RECEIVED,
    error: false,
    payload,
});

export const errorListsAction = payload => ({
    type: ActionTypes.DATA_LISTS_ERROR,
    error: true,
    payload,
});


export const requestedContentTypesAction = keywords => ({
    type: ActionTypes.DATA_CONTENT_TYPES_REQUESTED,
    keywords
});

export const requestedSearchIntentAction = keywords => ({
    type: ActionTypes.DATA_SEARCH_INTENT_REQUESTED,
    keywords
});

export const fetchingContentTypesAction = () => ({
    type: ActionTypes.DATA_CONTENT_TYPES_FETCHING,
});

export const receivedContentTypesAction = payload => ({
    type: ActionTypes.DATA_CONTENT_TYPES_RECEIVED,
    error: false,
    payload,
});

export const errorContentTypesAction = payload => ({
    type: ActionTypes.DATA_CONTENT_TYPES_ERROR,
    error: true,
    payload,
});

export const errorListsCreateAction = payload => ({
    type: ActionTypes.DATA_LISTS_CREATE_ERROR,
    error: true,
    payload,
});

export const skippedListsAction = () => ({
    type: ActionTypes.DATA_LISTS_SKIPPED,
});

export const receivedListsNewAction = payload => ({
    type: ActionTypes.DATA_LISTS_NEW_RECEIVED,
    error: false,
    payload,
});

export const receivedListsAddKeywordsAction = () => ({
    type: ActionTypes.DATA_LISTS_ADD_KEYWORDS_RECEIVED,
    error: false,
});

export const updateListsAddKeywords = ({ listId, keywordIds }) => ({
    type: ActionTypes.DATA_LISTS_ADD_KEYWORDS_OPTIMISTIC_UPDATE,
    payload: {
        listId,
        keywordIds,
    },
});

export const revertUpdateListsAddKeywords = ({ listId, keywordIds, updatedAt }) => ({
    type: ActionTypes.DATA_LISTS_ADD_KEYWORDS_OPTIMISTIC_UPDATE_REVERT,
    payload: {
        listId,
        keywordIds,
        updatedAt,
    },
});

export const errorListsAddKeywordsAction = payload => ({
    type: ActionTypes.DATA_LISTS_ADD_KEYWORDS_ERROR,
    error: true,
    payload,
});

export const requestedListsDeleteAction = ({ listId, listName }) => ({
    type: ActionTypes.DATA_LISTS_DELETE_REQUESTED,
    error: false,
    payload: {
        id: listId,
        name: listName,
    },
});

export const cancelledListsDeleteAction = id => ({
    type: ActionTypes.DATA_LISTS_DELETE_CANCELLED,
    error: false,
    payload: id,
});

export const fetchingListsDeleteAction = () => ({
    type: ActionTypes.DATA_LISTS_DELETE_FETCHING,
});

export const receivedListsDeleteAction = deletedListId => ({
    type: ActionTypes.DATA_LISTS_DELETE_RECEIVED,
    error: false,
    payload: deletedListId,
});

export const errorListsDeleteAction = payload => ({
    type: ActionTypes.DATA_LISTS_DELETE_ERROR,
    error: true,
    payload,
});

export const requestedListsRenameAction = (id, name) => ({
    type: ActionTypes.DATA_LISTS_RENAME_REQUESTED,
    error: false,
    payload: { id, name },
});

export const fetchingListsRenameAction = () => ({
    type: ActionTypes.DATA_LISTS_RENAME_FETCHING,
});

export const receivedListsRenameAction = (id, name) => ({
    type: ActionTypes.DATA_LISTS_RENAME_RECEIVED,
    error: false,
    payload: { id, name },
});

export const errorListsRenameAction = payload => ({
    type: ActionTypes.DATA_LISTS_RENAME_ERROR,
    error: true,
    payload,
});

export const requestedSelectedKwAddToListAction = (isNew, nameOrId) => ({
    type: ActionTypes.DATA_SELECTED_KW_ADD_TO_LIST_REQUESTED,
    error: false,
    payload: { isNew, nameOrId },
});

export const requestedCurrentKeywordsImportAction = () => ({
    type: ActionTypes.DATA_IMPORT_CURRENT_KEYWORDS_REQUESTED,
});

export const requestedKeywordsImportAction = (keywords, locationId) => ({
    type: ActionTypes.DATA_IMPORT_REQUESTED,
    error: false,
    payload: {
        keywords,
        locationId,
    },
});

export const fetchingImportAction = () => ({
    type: ActionTypes.DATA_IMPORT_FETCHING,
});

export const receivedImportAction = ({ data, meta }) => ({
    type: ActionTypes.DATA_IMPORT_RECEIVED,
    error: false,
    payload: {
        data,
        meta,
    },
});

export const errorImportAction = payload => ({
    type: ActionTypes.DATA_IMPORT_ERROR,
    error: true,
    payload,
});

export const requestedListsKeywordsAction = payload => ({
    type: ActionTypes.DATA_LISTS_KEYWORDS_REQUESTED,
    error: false,
    payload,
});

export const fetchingListKeywordsAction = () => ({
    type: ActionTypes.DATA_LISTS_KEYWORDS_FETCHING,
});

export const receivedListKeywordsAction = payload => ({
    type: ActionTypes.DATA_LISTS_KEYWORDS_RECEIVED,
    error: false,
    payload,
});

export const errorListKeywordsAction = payload => ({
    type: ActionTypes.DATA_LISTS_KEYWORDS_ERROR,
    error: true,
    payload,
});

export const emptyGoogleResultsAction = () => ({
    type: ActionTypes.DATA_RESULTS_GOOGLE_EMPTY,
});

export const fetchingGoogleResultsAction = () => ({
    type: ActionTypes.DATA_RESULTS_GOOGLE_FETCHING,
});

export const errorGoogleResultsAction = payload => ({
    type: ActionTypes.DATA_RESULTS_GOOGLE_ERROR,
    error: true,
    payload,
});

export const finishedGoogleResultsAction = () => ({
    type: ActionTypes.DATA_RESULTS_GOOGLE_FINISHED,
});

export const requestedGoogleAutocompleteResultsAction = () => ({
    type: ActionTypes.DATA_RESULTS_GOOGLE_AUTOCOMPLETE_REQUESTED,
});

export const requestedGoogleQuestionsResultsAction = () => ({
    type: ActionTypes.DATA_RESULTS_GOOGLE_QUESTIONS_REQUESTED,
});

export const requestedListExportAction = ({ listId }) => ({
    type: ActionTypes.DATA_LIST_EXPORT_REQUESTED,
    error: false,
    payload: { listId },
});

export const requestedSelectedKeywordsExportAction = () => ({
    type: ActionTypes.DATA_SELECTED_KW_EXPORT_REQUESTED,
    error: false,
});

export const rehydrationComplete = () => ({
    type: ActionTypes.DATA_REHYDRATATION_COMPLETE,
});

export const requestedDeleteSelectedKeywordsFromCurrentListAction = () => ({
    type: ActionTypes.DATA_LISTS_CURRENT_DELETE_KEYWORDS_REQUESTED,
});

export const errorDeleteSelectedKeywordsFromCurrentListAction = payload => ({
    type: ActionTypes.DATA_LISTS_CURRENT_DELETE_KEYWORDS_ERROR,
    error: true,
    payload,
});

export const cancelledDeleteSelectedKeywordsFromCurrentListAction = () => ({
    type: ActionTypes.DATA_LISTS_CURRENT_DELETE_KEYWORDS_CANCELLED,
});

export const fetchingAnnouncementsAction = () => ({
    type: ActionTypes.DATA_ANNOUNCEMENTS_FETCHING,
});

export const receivedAnnouncementsAction = payload => ({
    type: ActionTypes.DATA_ANNOUNCEMENTS_RECEIVED,
    error: false,
    payload,
});

export const errorAnnouncementsAction = payload => ({
    type: ActionTypes.DATA_ANNOUNCEMENTS_ERROR,
    error: true,
    payload,
});

export const requestedDeleteKeywordFromListAction = (keywordId, listId) => ({
    type: ActionTypes.DATA_LISTS_DELETE_KEYWORD_REQUESTED,
    payload: {
        keywordId,
        listId,
    },
});

export const fetchingCurrencyRatesAction = () => ({
    type: ActionTypes.DATA_CURRENCY_RATES_FETCHING,
});

export const errorCurrencyRatesAction = payload => ({
    type: ActionTypes.DATA_CURRENCY_RATES_ERROR,
    payload,
});

export const receivedCurrencyRatesAction = payload => ({
    type: ActionTypes.DATA_CURRENCY_RATES_RECEIVED,
    payload,
});

export const optimisticDeleteKeywordsFromListAction = ({ listId, keywordIds }) => ({
    type: ActionTypes.DATA_LISTS_DELETE_KEYWORDS_OPTIMISTIC,
    payload: {
        listId,
        keywordIds,
    },
});

export const revertOptimisticDeleteKeywordsFromListAction = () => ({
    type: ActionTypes.DATA_LISTS_DELETE_KEYWORDS_OPTIMISTIC_REVERT,
});

export const optimisticDeleteKeywordFromListAction = ({ listId, keywordId }) => ({
    type: ActionTypes.DATA_LISTS_DELETE_KEYWORD_OPTIMISTIC,
    payload: {
        listId,
        keywordId,
    },
});

export const revertOptimisticDeleteKeywordFromListAction = () => ({
    type: ActionTypes.DATA_LISTS_DELETE_KEYWORD_OPTIMISTIC_REVERT,
});

export const requestedCurrentListDataExportAction = () => ({
    type: ActionTypes.DATA_CURRENT_LIST_EXPORT_REQUESTED,
});

export const requestedSerpwatcherTrackingsAction = () => ({
    type: ActionTypes.DATA_SERPWATCHER_TRACKINGS_REQUESTED,
});

export const fetchingSerpwatchTrackingsAction = () => ({
    type: ActionTypes.DATA_SERPWATCHER_TRACKINGS_FETCHING,
});

export const receivedSerpwatcherTrackingsAction = payload => ({
    type: ActionTypes.DATA_SERPWATCHER_TRACKINGS_RECEIVED,
    payload,
});

export const errorSerpwatcherTrackingsAction = payload => ({
    type: ActionTypes.DATA_SERPWATCHER_TRACKINGS_ERROR,
    payload,
});

export const fetchingSearchIntentAction = () => ({
    type: ActionTypes.DATA_SEARCH_INTENT_RECEIVED,
});

export const receivedSearchIntentAction = (payload) => ({
    type: ActionTypes.DATA_SEARCH_INTENT_RECEIVED,
    payload,
});

export const errorSearchIntentAction = (error) => ({
    type: ActionTypes.DATA_SEARCH_INTENT_ERROR,
    payload: error,
});