import React from 'react';
import { node, number, string, bool } from 'prop-types';
import ReactTooltip from 'rc-tooltip';

const Tooltip = React.memo((props) => {
    const {
        visible,
        hoverable,
        mouseEnterDelay,
        mouseLeaveDelay,
        overlayContent,
        overlayClassName,
        placement,
        children,
        ...rest
    } = props;

    if (!visible) {
        return children;
    }

    const tooltipProps = {
        mouseEnterDelay,
        mouseLeaveDelay,
        overlay: overlayContent,
        overlayClassName,
        placement,
        prefixCls: 'tooltip',
        ...rest,
    };

    if (hoverable) {
        tooltipProps.trigger = ['hover'];
        tooltipProps.destroyTooltipOnHide = false;
        tooltipProps.overlayStyle = { pointerEvents: 'auto' };
    }

    return (
        <ReactTooltip {...tooltipProps}>
            {children}
        </ReactTooltip>
    );
});

Tooltip.propTypes = {
    children: node.isRequired,
    mouseEnterDelay: number,
    mouseLeaveDelay: number,
    overlayClassName: string,
    overlayContent: node.isRequired,
    placement: string,
    visible: bool,
    hoverable: bool,
};

Tooltip.defaultProps = {
    mouseEnterDelay: 0,
    mouseLeaveDelay: 0,
    overlayClassName: 'animated-once fade-in',
    placement: 'top',
    visible: true,
    hoverable: false,
};

export default Tooltip;
