import update from 'immutability-helper';
import { REHYDRATE } from 'redux-persist/constants';
import hasPath from 'ramda/src/hasPath';
import ActionTypes from 'constants/ActionTypes';
import { KEYWORD_COLUMNS } from 'constants/keywordColumns';

const initialState = {
    isDropdownVisible: false,
    visible: {
        [KEYWORD_COLUMNS.CONTENT_TYPE]: true,
        [KEYWORD_COLUMNS.SEARCH_INTENT]: true,
        [KEYWORD_COLUMNS.AVG_SEARCH_VOLUME]: true,
        [KEYWORD_COLUMNS.TREND]: true,
        [KEYWORD_COLUMNS.EV]: true,
        [KEYWORD_COLUMNS.POS]: true,
        [KEYWORD_COLUMNS.CPC]: true,
        [KEYWORD_COLUMNS.PPC]: true,
        [KEYWORD_COLUMNS.KEYWORD_DIFFICULTY]: true,
    }
};

const KEYWORD_COLUMNS_ARRAY = Object.keys(KEYWORD_COLUMNS)

const keywordsColumnsSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_KEYWORD_COLUMNS_SETTINGS_CHANGE: {
            if (!KEYWORD_COLUMNS_ARRAY.includes(action.payload.column)) {
                // just prevent from incorrect usage
                return state
            }
            return update(state, {
                visible: {
                    [action.payload.column]: {
                        $set: action.payload.value,
                    }
                },
            });
        }
        case ActionTypes.UI_KEYWORD_COLUMNS_SETTINGS_DROPDOWN_VISIBLE: {
            return update(state, {
                isDropdownVisible: {
                    $set: action.payload,
                },
            });
        }
        case REHYDRATE: {
            return update(state, {
                visible: {
                    $set: hasPath(['ui', 'columnsSettings', 'keywords'], action.payload)
                        ? action.payload.ui.columnsSettings.keywords.visible
                        : initialState.visible,
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default keywordsColumnsSettingsReducer;
