import config from 'appConfig';
import queryString from 'query-string';
import { parse, parseMeta, parseParams } from 'sources/parsers/ResultDataParser';

import ErrorCodes from 'mangools-commons/lib/constants/ErrorCodes';
import HumanService from 'mangools-commons/lib/services/HumanService';

// Constants
const ENDPOINT = '/related-keywords';

/**
 * Data source class handling communication with API for result list data.
 */
class ResultSource {
    static getData({ accessToken, params }) {
        const query = queryString.stringify({
            kw: params.query,
            language_id: params.languageId,
            location_id: params.locationId,
            // relatedKeywordFlow: 3 // Force dataforseo
        });

        const requestURL = `${config.API_HOST}${config.API_PATH}${ENDPOINT}?${query}`;

        return fetch(requestURL, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'X-Access-Token': accessToken || '',
                'X-Human-Token': HumanService.gen(),
                'X-Mangools-Client': 'app',
            },
        })
            .then(response => {
                if (response.ok) {
                    return response
                        .json()
                        .then(data => ({
                            error: false,
                            payload: {
                                // eslint-disable-next-line no-underscore-dangle
                                relatedKeywordId: data._id,
                                data: parse(data),
                                meta: parseMeta(data),
                                params: parseParams(data),
                            },
                        }))
                        .catch(ex => ({
                            error: true,
                            payload: {
                                requestURL,
                                status: ErrorCodes.PARSE_ERROR,
                                text: ex.toString(),
                            },
                        }));
                } else {
                    return response.json().then(e => ({
                        error: true,
                        payload: {
                            requestURL,
                            status: response.status,
                            text: e.error ? e.error.message : null,
                            type: e.error ? e.error.type : null,
                        },
                    }));
                }
            })
            .catch(ex => ({
                error: true,
                payload: {
                    requestURL,
                    status: ErrorCodes.FETCH_ERROR,
                    text: ex.toString(),
                },
            }));
    }
}

export default ResultSource;
