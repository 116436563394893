import { createSelector } from 'reselect';

const uiSelector = (state) => state.ui
export const appPanelVisibilitySelector = state => state.ui.panels.app.visibility;
export const colorSchemeSelector = state => state.ui.misc.colorScheme;
export const currentKeywordIdSelector = state => state.ui.dashboard.currentKeywordId;
export const filterActiveSelector = state => state.ui.filter.active;
export const filterQuickSettingsSelector = state => state.ui.filter.quickSettings;
export const filterSettingsSelector = state => state.ui.filter.settings;
export const historyPanelVisibilitySelector = state => state.ui.panels.history.visibility;
export const listPanelFilterSelector = state => state.ui.panels.list.filter;
export const listPanelVisibilitySelector = state => state.ui.panels.list.visibility;
export const navigatedInternallySelector = state => state.ui.misc.navigatedInternally;
export const newVersionNotificationShownSelector = state => state.ui.misc.newVersionNotificationShown;
export const onlineStatusSelector = state => state.ui.misc.onlineStatus;
export const selectedKeywordIdsSelector = state => state.ui.dashboard.selectedKeywordIds;
export const serpItemActionsDropdownVisibleIdSelector = state => state.ui.dashboard.serpItemActionsDropdownVisibleId;
export const sortingSettingsSelector = state => state.ui.dashboard.sortingSettings;

export const columnsSettingsSelector = createSelector([uiSelector], (state) => state.columnsSettings)
export const serpColumnsSettingsSelector = createSelector([columnsSettingsSelector], (state) => state.serps)
export const isSerpColumnsSettingsDropdownOpenSelector = createSelector([serpColumnsSettingsSelector], (state) => state.isDropdownVisible)
export const serpColumnsSettingsVisibleSelector = createSelector([serpColumnsSettingsSelector], (state) => state.visible)

export const keywordColumnsSettingsSelector = createSelector([columnsSettingsSelector], (state) => state.keywords)
export const isKeywordColumnsSettingsDropdownOpenSelector = createSelector([keywordColumnsSettingsSelector], (state) => state.isDropdownVisible)
export const keywordColumnsSettingsVisibleSelector = createSelector([keywordColumnsSettingsSelector], (state) => state.visible)

// Messages
const accessDeniedMessageVisibilitySelector = state => state.ui.messages.accessDenied.visibility;
const addToListMessageVisibilitySelector = state => state.ui.messages.addToList.visibility;
const deleteConfirmationMessageVisibilitySelector = state => state.ui.messages.deleteConfirmation.visibility;
const failureMessageVisibilitySelector = state => state.ui.messages.failure.visibility;
const gaugeInfoMessageVisibilitySelector = state => state.ui.messages.gaugeInfo.visibility;
const loggedOutMessageVisibilitySelector = state => state.ui.messages.loggedOut.visibility;
const noConnectionMessageVisibilitySelector = state => state.ui.messages.noConnection.visibility;
const needToSelectKeywordMessageVisibilitySelector = state => state.ui.messages.needToSelectKeyword.visibility;
const needToSignInMessageVisibilitySelector = state => state.ui.messages.needToSignIn.visibility;
export const upgradeForMoreResultsVisibilitySelector = state => state.ui.messages.upgradeForMoreResults.visibility;
export const shortcutsMessageVisibilitySelector = state => state.ui.messages.shortcuts.visibility;
export const addToListMessageAddingKeywordIdsSelector = state => state.ui.messages.addToList.addingKeywordIds;
const topLevelMessageVisibilitySelector = state => state.ui.messages.topLevel.visibility;
const urlTypeInfoMessageVisibilitySelector = state => state.ui.messages.urlTypeInfo.visibility;
export const currenciesMessageVisibilitySelector = state => state.ui.messages.currencies.visibility;
export const trackInSerpwatcherMessageVisibilitySelector = state => state.ui.messages.trackInSerpwatcher.visibility;
export const trackInSerpwatcherListIdSelector = state => state.ui.messages.trackInSerpwatcher.listId;

export const comparingBoxUrlSelector = state => state.ui.comparingBox.url;
export const comparingBoxUrlProtocolSelector = state => state.ui.comparingBox.urlProtocol;

// NOTE: Only show pricing message when need to sign in is not visible
export const pricingMessageVisibilitySelector = state =>
    state.ui.messages.pricing.visibility && !needToSignInMessageVisibilitySelector(state);

// Dropdowns
export const exportDropdownVisibilitySelector = state => state.ui.dropdowns.export.visibility;
export const helpDropdownVisibilitySelector = state => state.ui.dropdowns.help.visibility;
export const linksDropdownVisibilitySelector = state => state.ui.dropdowns.links.visibility;
export const listDetailActionsDropdownVisibilitySelector = state => state.ui.dropdowns.listDetailActions.visibility;
export const userDropdownVisibilitySelector = state => state.ui.dropdowns.user.visibility;

// Returns true if any of the dropdowns is visible
export const anyDropdownVisibleSelector = createSelector(
    [
        exportDropdownVisibilitySelector,
        helpDropdownVisibilitySelector,
        linksDropdownVisibilitySelector,
        listDetailActionsDropdownVisibilitySelector,
        userDropdownVisibilitySelector,
    ],
    (exportDropdownVisible, helpDropdownVisible, linksDropdownVisible, listDetailActionsDropdownVisible) =>
        helpDropdownVisible || linksDropdownVisible || exportDropdownVisible || listDetailActionsDropdownVisible,
);

// Misc

export const currencySelector = state => state.ui.misc.currency;

/* eslint-disable max-len */
export const overlayVisibilitySelector = createSelector(
    [
        appPanelVisibilitySelector,
        historyPanelVisibilitySelector,
        listPanelVisibilitySelector,
        failureMessageVisibilitySelector,
    ],
    (appVisibility, historyVisibility, listVisibility, failure) =>
        !failure && (appVisibility || historyVisibility || listVisibility),
);
/* eslint-enable max-len */

export const mobileOverlayVisibilitySelector = createSelector(
    [anyDropdownVisibleSelector],
    anyDropdownVisible => anyDropdownVisible,
);

const anyMessageVisibleSelector = createSelector(
    [
        accessDeniedMessageVisibilitySelector,
        addToListMessageVisibilitySelector,
        deleteConfirmationMessageVisibilitySelector,
        failureMessageVisibilitySelector,
        gaugeInfoMessageVisibilitySelector,
        loggedOutMessageVisibilitySelector,
        noConnectionMessageVisibilitySelector,
        needToSelectKeywordMessageVisibilitySelector,
        needToSignInMessageVisibilitySelector,
        upgradeForMoreResultsVisibilitySelector,
        shortcutsMessageVisibilitySelector,
        topLevelMessageVisibilitySelector,
        urlTypeInfoMessageVisibilitySelector,
        currenciesMessageVisibilitySelector,
        trackInSerpwatcherMessageVisibilitySelector,
    ],
    (
        accessDeniedMessageVisible,
        addToListMessageVisible,
        deleteConfirmationMessageVisible,
        failureMessageVisible,
        gaugeInfoMessageVisible,
        loggedOutMessageVisible,
        noConnectionMessageVisible,
        needToSelectKeywordMessageVisible,
        needToSignInMessageVisible,
        upgradeForMoreResultsVisible,
        shortcutsMessageVisible,
        topLevelMessageVisible,
        urlTypeInfoMessageVisible,
        currenciesMessageVisible,
        trackInSerpwatcherMessageVisible,
    ) =>
        accessDeniedMessageVisible ||
        addToListMessageVisible ||
        deleteConfirmationMessageVisible ||
        failureMessageVisible ||
        gaugeInfoMessageVisible ||
        loggedOutMessageVisible ||
        noConnectionMessageVisible ||
        needToSelectKeywordMessageVisible ||
        needToSignInMessageVisible ||
        upgradeForMoreResultsVisible ||
        shortcutsMessageVisible ||
        topLevelMessageVisible ||
        urlTypeInfoMessageVisible ||
        currenciesMessageVisible ||
        trackInSerpwatcherMessageVisible,
);

const anyPanelVisibilitySelector = createSelector(
    [appPanelVisibilitySelector, listPanelVisibilitySelector, historyPanelVisibilitySelector],
    (appPanelVisible, listPanelVisible, historyPanelVisible) =>
        appPanelVisible || listPanelVisible || historyPanelVisible,
);

export const areShortcutsDisabledSelector = createSelector(
    [anyDropdownVisibleSelector, anyPanelVisibilitySelector, anyMessageVisibleSelector],
    (anyDropdownVisible, anyPanelVisible, anyMessageVisible) =>
        anyDropdownVisible || anyPanelVisible || anyMessageVisible,
);

// Dashboard

export const keywordSourceResourceIdSelector = state => state.ui.dashboard.keywordSource.id;
export const keywordSourceResourceNameSelector = state => state.ui.dashboard.keywordSource.name;

export const relativeKdActiveSelector = state => state.ui.relativeKd.active;
export const relativeKdDropdownVisibilitySelector = state => state.ui.relativeKd.visibility;
