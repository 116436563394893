import update from 'immutability-helper';
import { REHYDRATE } from 'redux-persist/constants';
import hasPath from 'ramda/src/hasPath';
import ActionTypes from 'constants/ActionTypes';
import { SERP_COLUMNS } from 'constants/serpColumns';

const initialState = {
    isDropdownVisible: false,
    visible: {
        [SERP_COLUMNS.DOMAIN_AUTHORITY]: true,
        [SERP_COLUMNS.PAGE_AUTHORITY]: true,
        [SERP_COLUMNS.CITATION_FLOW]: true,
        [SERP_COLUMNS.TRUST_FLOW]: true,
        [SERP_COLUMNS.EXTERNAL_BACKLINKS]: true,
        [SERP_COLUMNS.FACEBOOK_SHARES]: false,
        [SERP_COLUMNS.LINK_PROFILE_STRENGTH]: true,
        [SERP_COLUMNS.ESTIMATED_VISITS]: true,
    }
};

const SERP_COLUMNS_ARRAY = Object.keys(SERP_COLUMNS)

const serpsColumnsSettingsReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.UI_SERP_COLUMNS_SETTINGS_CHANGE: {
            if (!SERP_COLUMNS_ARRAY.includes(action.payload.column)) {
                // just prevent from incorrect usage
                return state
            }
            return update(state, {
                visible: {
                    [action.payload.column]: {
                        $set: action.payload.value,
                    }
                },
            });
        }
        case ActionTypes.UI_SERP_COLUMNS_SETTINGS_DROPDOWN_VISIBLE: {
            return update(state, {
                isDropdownVisible: {
                    $set: action.payload,
                },
            });
        }
        case REHYDRATE: {
            return update(state, {
                visible: {
                    $set: hasPath(['ui', 'columnsSettings', 'serps'], action.payload)
                        ? action.payload.ui.columnsSettings.serps.visible
                        : initialState.visible,
                },
            });
        }
        default: {
            return state;
        }
    }
};

export default serpsColumnsSettingsReducer;
